/* eslint-disable max-len, vue/max-len */
/**
 * @file 出海易订购协议
 * @author dingxin02@baidu.com
 */

export const data = {
    'zh-CN': {
        title: '出海易产品订购协议',
        // 版本号
        version: '202203-V1',
        // 范本编号
        templateNo: 'FBOT20220069',
        content: `
            <p>本协议由购买出海易产品的客户（以下简称“甲方”或“您”） 与出海易平台（以下简称“乙方”或“百度”、“百度公司“）签署。</p>
            <p><strong>【审慎阅读】</strong></p>
            <p><strong>甲方确认，在您接受本公司的服务之前，或您以其他本公司允许的方式实际使用本公司服务之前，您已充分阅读、理解并接受本协议（包括乙方不时发布或修订的相关条款）的全部内容，一旦您使用本协议服务内容，即表示您同意遵循本协议之所有约定。</strong></p>
            <p><strong>乙方提醒您认真阅读、充分理解本协议各条款内容，特别是以粗体标注部分。出海易仅提供技术服务，因您上传发布企业、商品等信息及其他展示内容等引起的争议，由您自行承担并解决。双方约定协议具体内容如下：</strong></p>
            <h4>一、定义</h4>
            <p>1、出海易： 指百度旗下的跨境服务平台，旨在给商家提供一站式跨境解决方案，触达海量优质商家，包含出海易网站（<a href="https://chuhaiyi.baidu.com" target="_blank">chuhaiyi.baidu.com</a>）PC端以及后期可能开发的移动端、APP等。</p>
            <p>2、<strong>第三方服务商平台： 是指与乙方正式签署《出海易平台服务商年度合同》，且在当地工商局正式注册并取得营业执照的企业，可代理本合同约定范围内的出海易产品的分销与服务，同时已与商家签署相关服务协议并协助商家使用出海易产品的服务商，以下亦简称“服务商”。</strong></p>
            <p>3、出海易产品： 是指出海易平台面向商家提供的一站式跨境解决方案，旗下包含出海易会员、出海易广告投放、以及出海易新开发和运营的其他产品。产品及单品如有调整以出海易发布的产品通知为准。</p>
            <p>4、商家： 指自行购买或委托经授权的第三方服务商购买后，使用出海易产品的终端客户，商家需按照出海易平台的要求提供注册所需资料，服务商需获得商家授权为商家注册出海易账号，并要求商家提供注册所需资料，并保证所提供的资料真实有效，符合国家相关法律法规，可合法、有效地在出海易展示。</p>
            <p>5、用户： 指商家的服务提供对象。</p>
            <h4>二、出海易产品类型</h4>
            <p>1、出海易会员：是指出海易平台提供的包含基础服务的产品，商家提交合法有效的主体资质及行业资质，入驻到出海易平台，享受出海易相关权益（具体详情请见：<a href="https://chuhaiyi.baidu.com" target="_blank">chuhaiyi.baidu.com</a>）。</p>
            <p>2、出海易推广产品：指商家通过入驻出海易平台成为出海易会员后，由出海易联合第三方推广营销公司，为商家提供的推广策略制定、数据产出、营销投放等推广相关的技术服务。</p>
            <p>3、出海易新开发和运营的其他产品，具体以出海易网站公示的产品类型及产品介绍为准。</p>
            <h4>三、出海易产品使用规范</h4>
            <p>1、您自愿通过出海易平台购买相应付费服务，且您承诺并保证本人在申请开通付费服务时具有完全民事行为能力。</p>
            <p>2、<strong><u>您保证您在出海易注册账号、绑定电话号码、上传资质包括但不限于营业执照、商标权利证明、上传商品数据等行为均为您本人合法获得且真实有效，并处于您本人有效掌控下。因您变更绑定手机号码、遗失账户，或将绑定手机号码、账户转借、转租他人使用，或因您原因造成的资料及交易信息等泄露所产生的风险及损失，由您自行承担，出海易平台不承担任何责任。</u></strong></p>
            <p>3、您可在出海易平台订购出海易产品，请您务必仔细了解本协议及确认相关具体信息（包括但不限于产品的内容、产品的服务期限、产品价格等），并根据操作提示，确认自己的账号并选择相关操作选项。</p>
            <p>4、<strong>您理解并同意：出海易产品实行先付款后使用的方式，您及时、足额、合法的支付所需的款项是您获得该等服务的前提。您有权选择使用出海易提供认可的支付方式，您理解并确认支付服务由出海易之外具备合法资质的第三方提供，该等支付服务的使用条件及规范由您与支付服务提供方确定，与出海易无关。</strong></p>
            <p>5、<strong>出海易对所提供的产品的适用性或满足您特定需求及目的不承担任何明示或者默示的担保。请您在付费前确认自身的需求，同时仔细阅读相关产品的详情说明。</strong></p>
            <p>6、您知悉并同意，您购买的出海易产品仅限于商家自行使用，如果您是以服务商身份代商家购买的，则仅限于服务商获得授权的对应商家使用，除前述情况外，您无权对购买的出海易产品进行出售、转让、许可或以其他方式使除您自己以外的第三方（包括但不限于自然人、法人或其他组织）使用。若您违反本条规定，则出海易有权视情况采取如下措施而无需承担任何责任：</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1) 取消您继续使用该产品的权利；</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2) 限制、冻结、终止您的帐号；</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3) 要求您退还通过出售、转让、许可等其他方式取得的收益；</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4) 其他出海易认为可以采取的救济措施。</p>
            <p>7、您了解并同意，出海易可能会基于自身原因（包括但不限于：市场需要）不定期的对产品内容或功能进行更新而无需经过您的事先同意。</p>
            <p>8、<strong>您应保管好自己的帐号、密码等在出海易使用的账号，如因您未保管好自己的账号等而对自己、出海易或第三方造成损害的，您将负全部责任。</strong></p>
            <p>9、如果我们发现因平台系统故障等原因（包括但不限于产品名称、价格或数量等关键信息存在错误等）导致的订购结果处理错误，无论有利于出海易还是有利于您，出海易都有权在以平台公告或其他合理方式通知您后纠正该错误（包括但不限于取消交易订单等）。</p>
            <p>10、<strong>如果您是以服务商身份代商家购买的出海易产品的，您需确保已获得商家的授权，并承诺已审核商家营业执照、法人身份信息、从事该行业所需的相关证照、有效联系方式等相关资质、证明等，确保商家的真实、准确、合法、有效。若因商户资质不全、虚假、失效等产生法律责任的，由甲方承担全部责任，与乙方及其关联公司无关。如因此给乙方造成损失的，甲方应当予以全部赔偿。甲方应保证商家的真实性、合法性，不存在商家或其他不对应的情况。如因违反本条款约定而引发的任何纠纷，甲方均应当负责并承担法律责任，同时应承担由此给乙方带来的全部损失。</strong></p>
            <h4>四、数据传输与安全</h4>
            <p>1、您应按照本协议约定的产品接入方式接入，并根据出海易平台的接入标准和技术参数，对自身系统进行相应的改造，以满足产品接入和展示要求。</p>
            <p>2、您应保证自身系统及产品数据的安全性，保证系统及产品数据中不含有病毒、恶意程序或其他损害百度各平台安全性的信息和数据。</p>
            <p>3、您需向出海易平台传输的数据类型和出海易需向您传输的数据，以出海易平台的相关技术标准和要求为准。</p>
            <p>4、出于数据安全考虑，出海易在认为必要时可取消您访问出海易平台接口的权利并通知您。在相关安全威胁解除后，出海易平台可选择恢复您接口访问的权利。</p>
            <h4>五、服务费用说明</h4>
            <p><strong>1、您承诺并同意，在出海易平台上进行的各类交易所产生的电子信息均为该交易的有效凭证，甲方在出海易平台转入资金、购买产品等相关操作均视为甲方发出的有效指令，视为甲方所为，由此产生的电子信息记录作为乙方处理甲方交易的有效凭证。甲方通过出海易平台的交易指令一经发出，不得撤销，甲方同意通过出海易平台发出指令均视为甲方真实意思的表示。</strong></p>
            <p>2、<strong>您每次购买产品时，以购买时当期服务包含的权益为准， 您购买后，不排除相关服务权益后续发生变化，具体变化以出海易产品政策为准。 </strong>您可通过网银或者充值账户中余额进行支付，在此情况下，会在相关页面上做明确的提示。如您拒绝支付该等费用，则不能使用相关的网络服务或增值服务。如您购买付费业务，则视为接受付费业务的服务条款。</p>
            <p>3、乙方在收到款项后，可根据实际收款金额为您开具等额发票，开票信息以甲方通过出海易平台登记内容为准，如由于甲方原因引起的换票、退票、错票、遗失等，而导致乙方产生损失，如登报挂失、税费损失等，责任将由甲方承担，甲方应予相应赔偿。</p>
            <p>4、乙方所有产品均设置了有效期，不同产品包含的权益内容、价格及有效期均不同。乙方收到款项后，为您下发相关权益，服务有效期开始，有效期结束后服务将自动终止，且有效期不可中断或延期，您可通过续费或者再次购买产品重新获得相应权益。乙方产品的服务开始日期及有效期以乙方平台或邮件发布的规则为准。 <strong>您签署本合同后，百度即已开始为您使用乙方产品安排技术资源、人员服务等进行准备和调试，除非另有规定，所有付费业务一旦付费成功则不支持给您退费。</strong></p>
            <p>5、<strong>乙方有权根据不同的产品，不同的服务设定不同的服务价格，并有权根据市场之需求对价格进行调整。您购买乙方服务产品的价格以甲方在线下单时本网站上公布的价格为准。甲方对购买之服务的价格表示确认或同意的，不得以本网站变更后的价格（如有）向本网站主张索赔或返还一定的金额等。如上述行为发生的，本网站有权不予赔偿或退还相应服务费等。</strong></p>
            <h4>六、保密</h4>
            <p>1、双方或其雇员、职员因签订或履行本协议而获得的有关对方的任何信息，包括且不限于有关技术、财务、市场、管理等方面的科学、商业或内部信息，均为双方的保密信息，应为各方的专有财产。</p>
            <p>2、您应保证妥善保管乙方传输给您的信息，确保数据信息的安全性，保证仅限将上述信息用于为用户提供合作产品的服务，不得将上述信息提供给任何第三方或用于履行本协议以外之目的。</p>
            <p>3、双方相互承诺对对方的保密信息严格保密，且仅用于本协议之目的，除下列情况外不得使用或允许他人使用保密信息，或向任何第三方披露保密信息：</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1) 经另一方书面同意。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2) 按照有管辖权的法院、任何政府部门或行政机构的命令或要求。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3) 该信息非因一方或其代理人、职员或雇员的故意、过失或疏忽的原因而广为公众所知。</p>
            <p>4、双方有义务只将保密信息透露给有必要知悉的对方雇员，并指示该雇员承担本协议的保密义务，并对雇员违反保密义务承担责任。</p>
            <p>5、本保密条款不因本协议的无效、解除、提前终止、不具操作性而失效。</p>
            <h4>七、声明及保证</h4>
            <p>双方互相向对方声明、陈述和保证如下：</p>
            <p>1、其是合法设立并有效存续的独立法人，或是具有完全的民事行为能力，并能够独立承担法律责任的自然人。</p>
            <p>2、其有资格从事本协议项下之合作，而该合作符合其经营范围之规定。</p>
            <p>3、其有能力履行其于本协议项下之义务；并且该等履行义务的行为不违反任何对其有约束力的法律文件的限制。</p>
            <p>4、甲方保证其向乙方提供的全部信息，包括但不限于全部证明文件、经营信息等，真实、准确、合法，且在协议履行期间都处于合法有效期内，不可存在任何误导、遗漏、虚假或隐瞒，否则，因此给第三方和/或乙方带来损失的，应由甲方独立承担全部法律责任并予以赔偿。</p>
            <h4>八、违约责任</h4>
            <p>1、甲、乙双方任何一方直接或间接违反本合同的任何条款或不及时、充分地承担本合同项下其应当承担的义务而构成违约行为的，守约方有权以书面通知要求违约方纠正其违约行为并采取充分、有效、及时的措施消除违约后果并赔偿守约方因违约方之违约行为而遭致的损失。任何一方因过错，给对方带来损失，包括经济损失、名誉损失等，守约方有权提出解除合同，并要求对方赔偿损失。</p>
            <p>2、在违约事实发生以后，经守约方的合理及客观的判断该等违约事实已造成守约方履行本合同项下其相应的义务已不可能或不公平，守约方有权以书面通知要求违约方纠正其违约行为，并采取充分、有效、及时的措施消除违约后果，并赔偿守约方因此而遭致的损失。若违约方在收到守约方关于其违约行为的上述通知后十五个工作日内未纠正其违约行为，守约方有权书面通知合同相对方终止本合同。</p>
            <p>3、一方违约的，合同相对方应当在合理范围内采取适当措施防止损失的扩大。没有采取适当措施致使损失扩大的，不得就扩大的损失要求赔偿。一方因防止损失扩大而支出的合理费用应由违约方承担。如守约方不采取措施减少损失，对于本应减轻的损失数额，违约方有权要求从损害赔偿中扣除。</p>
            <p>4、违约方因其违约行为而应赔偿的守约方的损失包括但不限于守约方因违约方的违约行为而遭致的直接的经济损失及任何可预期的间接损失及其他合理的费用，包括但不限于律师费用、诉讼及仲裁费用、财务费用及差旅费等。</p>
            <p>5、甲方出现下列情形之一，乙方在法律允许范围内有权选择要求甲方做出有效整改、承担违约责任包括但不限于承担违约金，赔偿损失，情节严重的，乙方有权立即终止合作：</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1) 因甲方信息出现违法违规或违反约定等情形或重大负面影响的，乙方要求整改和/或引起用户投诉举报或起诉的，甲方应在乙方告知后立即响应并解决。如因此造成以方损失的，甲方应赔偿乙方全部损失。情节严重的，乙方有权终止与甲方继续合作。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2) 甲方应保证合作期限内所提供数据接口的合法性、一致性、稳定性和准确性，符合乙方对接口规范的要求，如接口或数据质量、数量不达标，或甲方系统功能存在严重缺陷的，乙方有权要求甲方立即改正。如甲方多次（三次及以上）接口、数据、数量等不达标或存在重大问题的，乙方有权终止与甲方继续合作。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3) 甲方严重诋毁乙方声誉，乙方有权终止合作并要求甲方承担违约金。</p>
            <h4>九、协议终止</h4>
            <p>1、本协议终止，双方应书面声明已删除所有对方涉密信息，且双方应对协议终止后的未完事项妥善进行处理，包括但不限于未结算款项的结算、用户已购买产品的交付及售后服务等内容。</p>
            <p>2、发生下列情形时，协议终止。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1) 双方协商一致提前终止协议。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2) 协议到期，双方未续约或签署新的替代协议的。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3) 任何一方经营策略调整，有权经提前30天书面通知乙方后，终止本协议。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4) 甲方出现本协议所约定严重违约情形的。</p>
            <h4>十、不可抗力</h4>
            <p>1、“不可抗力”是指本协议双方不能合理控制、不可预见或即使预见亦无法避免的事件，该事件使任何一方根据本协议履行其全部或部分义务已不可能。该事件包括但不限于政府行为、地震、台风、洪水、火灾或其它天灾、战争或任何其它类似事件。鉴于互联网之特殊性质，不可抗力亦包括下列影响互联网正常运行之情形：</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1) 黑客攻击。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2) 电信部门技术调整导致之重大影响，因该方管理不善造成的损失不包含在内。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3) 因政府管制而造成之暂时性关闭，因该方之过错导致的管制除外。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4) 病毒侵袭。</p>
            <p>2、如发生不可抗力事件，遭受该事件的一方应立即用可能的最快捷的方式通知另一方该事件的性质、发生日期、预计持续时间等有关的细节，以及该事件阻碍通知方履行其于本协议项下义务的程度。</p>
            <p>3、在不可抗力事件持续期间，遭受不可抗力事件的一方应定期及时地告知另一方不可抗力事件的现状，如不可抗力事件结束，应立即以书面形式通知对方，并继续履行协议项下义务。</p>
            <p>4、遭受不可抗力事件的一方在履行了本条第2、3款规定的义务后可暂行中止履行本协议项下的义务直至不可抗力事件的影响消除为止，并且无需为此而承担违约责任；但应尽最大努力克服该事件，减轻其负面的影响。</p>
            <h4>十一、知识产权</h4>
            <p>1、乙方对其提供的服务、产品及相关技术享有知识产权或相关授权，未经乙方书面许可，甲方不得更改、演绎、拆分、反解甲方的技术和程序。甲方不得以任何形式获得乙方程序的源代码。未经授权，不得将乙方提供的服务用于其它用途。</p>
            <p>2、甲方违反前款承诺的，乙方有权随时终止本协议，乙方因此遭受损失的，由甲方承担。</p>
            <p>3、如因任何一方在本协议项下合作中侵犯了任何其他方的知识产权或其他合法权利，或其提供的产品、服务、资料等侵犯了任何其他方的知识产权或其他合法权利，给本协议另一方或其关联方造成损失的，该方应赔偿该等损失。</p>
            <p>4、未经乙方同意，甲方不得申请注册以及使用任何与百度商标、LOGO等相同或近似的商标名称。</p>
            <h4>十二、其他</h4>
            <p>1、适用法律和争议解决：本协议适用中华人民共和国法律（港、澳、台地区除外），因本协议而产生或与本协议有关的任何争议，双方应通过友好协商解决。如果任何争议在一方向另一方发出协商解决争议的书面通知后三十（30）天内通过友好协商仍无法解决，则任何一方可向乙方所在地人民法院提起诉讼。</p>
            <p>2、<strong>本协议内容包括本协议正文、<u><a
                href="https://chuhaiyi.baidu.com/agrt/service" name="出海易商家服务协议"
                target="_blank"
            >《出海易商家服务协议》</a></u>、 <u><a
                href="https://trust.baidu.com/agreement.html" name="《百度信誉认证认证技术协议》"
                target="_blank"
            >《百度信誉认证认证技术协议》</a></u>、 及注册采购平台账号或使用与本合同有关的服务时与百度公司签订的协议及百度网站中公布相关百度产品政策等，上述协议及公示政策应视为本协议不可分割的组成部分，与本协议具有同等法律效力 ，商家确认已阅读并同意签署协议，如果甲方是服务商，甲方应保证其代为购买出海易产品的商家对前述协议知晓且完全同意。</strong></p>
            <p>3、可分割性：若本协议任一条款无效或无法履行，则该条款将于法律允许的最大范围内履行，除该无效或无法履行部分以外，本协议的其他部分仍然有效。</p>
        `,
    },
};
